import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import {Dialog} from 'common/Dialog';
import {FormEvent, useState} from 'react';
import {emailSchema} from '../validation';
import {TOURMALINE_ENDPOINT} from 'common/apiClient/constatns';
import {useToast} from 'common/toast/useToast';
import {notifyError} from 'common/errorTracker/errrorTracker';
import {useTranslation} from 'next-i18next';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const SendResetPasswordDialog = ({isOpen, onClose}: Props) => {
  const [shouldValidate, setShouldValidate] = useState(false);
  const [email, setEmail] = useState('');
  const emailValidationResult = emailSchema.safeParse(email);
  const emailError = emailValidationResult.success
    ? null
    : emailValidationResult.error.errors?.[0]?.message;
  const emailDisplayError = shouldValidate ? emailError : null;
  const handleClose = () => {
    onClose();
    setEmail('');
    setShouldValidate(false);
  };
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setShouldValidate(true);
    if (emailError) return;
    setIsLoading(true);
    const fd = new FormData();
    fd.append('email', email);
    fetch(TOURMALINE_ENDPOINT + 'send_reset_password_email', {
      method: 'POST',
      body: fd,
    })
      .then(() => {
        toast({
          operation: 'send_reset_password_email',
          resource: 'user',
        });
        handleClose();
        setIsLoading(false);
      })
      .catch(err => {
        notifyError(err);
        toast({
          operation: 'send_reset_password_email',
          resource: 'user',
          error: err,
        });
        setIsLoading(false);
      });
  };

  const {t} = useTranslation('auth');

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={t('パスワードのリセット')}
      width="xl"
    >
      <Stack spacing="large">
        <Text variant="text2">
          {t(
            '入力したメールアドレスにパスワードリセット用のメールを送信します。'
          )}
        </Text>
        <form onSubmit={handleSubmit}>
          <Stack spacing="small">
            <FormControl isInvalid={!!emailDisplayError}>
              <FormLabel fontSize="memo1" color="color.hambleA11y">
                {t('メールアドレス')}
              </FormLabel>
              <Input
                variant="amethyst"
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <FormErrorMessage>{emailDisplayError}</FormErrorMessage>
            </FormControl>
            <Button
              type="submit"
              variant="amethyst"
              alignSelf="flex-end"
              isLoading={isLoading}
            >
              {t('送信する')}
            </Button>
          </Stack>
        </form>
      </Stack>
    </Dialog>
  );
};
