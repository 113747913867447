import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  Link,
  Alert,
  AlertIcon,
  Text,
} from '@chakra-ui/react';
import {FormEvent, useState} from 'react';
import {emailSchema, passwordSchema} from '../validation';
import {useToast} from 'common/toast/useToast';
import {TOURMALINE_ENDPOINT} from 'common/apiClient/constatns';
import {notifyError} from 'common/errorTracker/errrorTracker';
import {TOURMALINE_TOKEN_LOCAL_STORAGE_KEY} from 'common/storage';
import {pagesPath} from 'lib/$path';
import {useRouter} from 'next/router';
import {SendResetPasswordDialog} from './SendResetPasswordDialog';
import {useTranslation} from 'next-i18next';

export const LoginWithEmailForm = () => {
  const [shouldValidate, setShouldValidate] = useState(false);
  const router = useRouter();

  const [email, setEmail] = useState('');
  const emailParsedResult = emailSchema.safeParse(email);
  const emailError = emailParsedResult.success
    ? null
    : emailParsedResult.error.errors?.[0]?.message;
  const emailDisplayError = shouldValidate ? emailError : null;

  const [password, setPassword] = useState('');
  const parseResult = passwordSchema.safeParse(password);
  const passwordError = parseResult.success
    ? null
    : parseResult.error.errors?.[0]?.message;
  const passwordDisplayError = shouldValidate ? passwordError : null;

  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setShouldValidate(true);

    if (emailError || passwordError) {
      return;
    }

    const fd = new FormData();
    fd.append('email', email);
    fd.append('password', password);
    fd.append('redirect_url', window.location.origin + '/login/callback');

    setIsLoading(true);

    fetch(TOURMALINE_ENDPOINT + 'login_with_email', {
      method: 'POST',
      body: fd,
    })
      .then(res => res.json())
      .then(body => {
        const token = body.token;
        if (!token) throw new Error('Empty token');
        localStorage.setItem(TOURMALINE_TOKEN_LOCAL_STORAGE_KEY, token);
        setIsLoading(false);
        toast({resource: 'user', operation: 'login'});
        router.push(pagesPath.$url());
      })
      .catch(e => {
        notifyError(e);
        setIsLoading(false);
        toast({resource: 'user', operation: 'login', error: e});
      });
  };

  const [isOpenResetPasswordDialog, setIsOpenResetPasswordDialog] =
    useState(false);

  const {t} = useTranslation('auth');

  return (
    <Stack spacing="regular">
      <Text variant="text2Bold">{t('メールアドレスでログイン')}</Text>
      <form onSubmit={handleSubmit}>
        <FormControl id="email" isInvalid={!!emailDisplayError}>
          <FormLabel fontSize="memo1" color="color.hambleA11y">
            {t('メールアドレス')}
          </FormLabel>
          <Input
            variant="amethyst"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <FormErrorMessage>{emailDisplayError}</FormErrorMessage>
        </FormControl>
        <FormControl
          id="password"
          isInvalid={!!passwordDisplayError}
          mt="small"
        >
          <FormLabel fontSize="memo1" color="color.hambleA11y">
            {t('パスワード')}
          </FormLabel>
          <Input
            variant="amethyst"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <FormErrorMessage>{passwordError}</FormErrorMessage>
        </FormControl>
        <Button
          variant="amethyst"
          type="submit"
          mt="small"
          w="100%"
          isLoading={isLoading}
        >
          {t('ログイン')}
        </Button>
      </form>

      <Text>
        <Link
          fontSize="memo1"
          color="color.variation"
          as="button"
          onClick={() => setIsOpenResetPasswordDialog(true)}
        >
          {t('パスワードをお忘れですか？')}
        </Link>
      </Text>
      <SendResetPasswordDialog
        isOpen={isOpenResetPasswordDialog}
        onClose={() => setIsOpenResetPasswordDialog(false)}
      />
      <Alert status="warning" fontSize="memo1" rounded="lg" shadow="lg">
        <AlertIcon />
        {t(
          'Amethyst は、一部の機能で Google アカウントと紐づいた Google Search Console の機能を使用します。全機能をご利用いただくには、Google ログインが必要です。メールアドレスでログインされた場合は、機能が制限されますのでご注意ください。'
        )}
      </Alert>
    </Stack>
  );
};
