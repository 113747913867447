import {Image, Stack, Text} from '@chakra-ui/react';
import {Dialog} from 'common/Dialog';
import {staticPath} from 'lib/$path';
import {useRouter} from 'next/router';
import {useState} from 'react';
import {useTranslation} from 'next-i18next';
import {GoogleLoginButton} from '../GoogleLoginButton';

export const PermissionDeniedDialog = () => {
  const router = useRouter();
  const isDenied = router.query.error === 'permission_denied';

  if (!isDenied) return null;

  return <PermissionDeniedDialogPresentation />;
};

export const PermissionDeniedDialogPresentation = () => {
  const [open, setOpen] = useState(true);
  const {t, i18n} = useTranslation('auth');
  const lang = i18n.language === 'ja' ? 'ja' : 'en';
  return (
    <Dialog
      isOpen={open}
      onClose={() => setOpen(false)}
      title={t('allowPermission.title')}
    >
      <Stack spacing="regular" justifyContent="center" alignItems="center">
        <Text variant="text2">{t('allowPermission.description')}</Text>
        <Image
          maxW="xs"
          src={staticPath.images.google_login[lang].sc_permission_png}
          alt={t('allowPermission.description')}
        />
        <GoogleLoginButton />
      </Stack>
    </Dialog>
  );
};
