import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Center,
  Stack,
  Text,
} from '@chakra-ui/react';
import {NextPageWithLayout} from 'common/types';
import {pagesPath} from 'lib/$path';
import {useRouter} from 'next/router';
import {useEffect} from 'react';
import {GoogleLoginAlert} from '../GoogleLoginAlert';
import {PermissionDeniedDialog} from './PermissionDeniedDialog';
import {Env} from 'env/env';
import {LoginWithEmailForm} from './LoginWithEmailForm';
import {useTranslation} from 'next-i18next';
import {NotLoggedInLayout} from 'common/app/NotLoggedInLayout';
import {pushEvent} from 'common/analytics/pushEvent';

const DEMO_ORG_ID = 'demo';

export const LoginPage: NextPageWithLayout = () => {
  const {t} = useTranslation('auth');
  const router = useRouter();

  useEffect(() => {
    pushEvent({
      event: 'page_view',
      userid: null,
      orgid: null,
      orgname: null,
      content_group: 'ログインページ',
      iwjobname: null,
      projname: null,
      saviewname: null,
      jobname: null,
      plan: null,
    });
    if (Env.ENV === 'demo') {
      // if demo mode, skip login and redirect to demo org
      router.push(pagesPath.orgs._orgId(DEMO_ORG_ID).$url());
    }
  }, [router]);

  return (
    <>
      <Center pt="150">
        <Stack width="2xl" maxWidth="100%" p="2">
          <Text variant="text1Bold">{t('ログイン')}</Text>
          <GoogleLoginAlert />
          <Accordion allowToggle>
            <AccordionItem>
              <AccordionButton>
                <AccordionIcon />
                {t('その他のオプション')}
              </AccordionButton>
              <AccordionPanel>
                <LoginWithEmailForm />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Stack>
      </Center>
      <PermissionDeniedDialog />
    </>
  );
};

LoginPage.getLayout = page => <NotLoggedInLayout>{page}</NotLoggedInLayout>;
