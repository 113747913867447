import {LoginPage} from 'features/auth/login/LoginPage';
export default LoginPage;

import {GetStaticProps} from 'next';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';
export const getStaticProps: GetStaticProps = async ({locale}) => ({
  props: {
    ...(await serverSideTranslations(locale!, [
      'auth',
      'toast',
      'zod',
      'layout',
    ])),
  },
});
